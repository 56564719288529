"use strict";
import $ from 'jquery';

import { Dialog, Intl } from 'Roblox';
import CaptchaConstants from '../constants/captchaConstants';
import CaptchaLogger from './captchaLogger';

const jqFunCaptchaService = (function() {

    var captchaTypes = [],
        messages = $.extend({}, CaptchaConstants.messages),
        funCaptchaTokenElem = "#FunCaptcha-Token",
        funCaptchaIFrameElem = "#fc-iframe-wrap",
        funCaptchaIFrameFocusTimeout = 500,
        retryQueued = false,
        maxRetriesOnValidationFailure = 0,
        minRetryInterval = 500, // defined in milliseconds
        maxRetryInterval = 1500, // defined in milliseconds
        logger = new CaptchaLogger("FunCaptcha"),
        captchaTypeDedupe = {};

    var captchaInstances = {},
        defaultParams = {
            cType: null,
            tokenValidationRetries: 0,
            extraValidationParams: {},
            returnTokenInSuccessCb: false,
            inputParams: {},
            solvedCb: $.noop,
            loadedCb: $.noop,
            supressCb: $.noop,
            shownCb: $.noop,
            successCb: $.noop,
            errorCb: $.noop,
            fcInstance: null
        },
        userStartedFunCaptchaTime;

    function getCaptcha(type) {
        for (var i = 0; i < captchaTypes.length; i++) {
            if (captchaTypes[i].Type === type) {
                return captchaTypes[i];
            }
        }

        return null;
    }

    //resets the funcaptcha instance associated with supplied id.
    function resetFunCaptcha(id) {
        if (captchaInstances[id]) {
            captchaInstances[id].fcInstance.refresh_session();
        }
    }

    /*
     elemId - Id of element to render FC on.
     Values inside params
        cType**: captcha action type to perform
        solvedCb: triggered when users solved FC
        loadedCb: triggered when users solved FC
        suppressCb: triggered when FC doesn't challenge user
        shownCb: triggered when FC shows challenge to user
        successCb**: when user is successful at passing all of Captcha flow
        returnTokenInSuccessCb: return captcha token and provider info if set to true
        inputParams: parameters are required for captcha, e.g. data exchange blob.
        errorCb: when there's an error with the captcha flow
        ** = required.
     */
    function renderFunCaptcha(elemId, params) {
        retryQueued = false;
        logger.logTriggered(params.cType);
        var dxBlob = ""
        if (params.inputParams) {
            dxBlob = (params.inputParams.dataExchange == null) ? "" : params.inputParams.dataExchange;
        }
        if (captchaInstances[elemId] && captchaInstances[elemId].fcInstance) {
            //if we already rendered this before, just trigger again.
            captchaInstances[elemId].fcInstance.data = {"blob": dxBlob};
            resetFunCaptcha(elemId);
            return elemId;
        }
        var fcParams = makeInstance(elemId, params);
        userStartedFunCaptchaTime = timestamp();
        logger.logInitialized(fcParams.cType);
        var captchaTypeInfo = getCaptcha(fcParams.cType);
        if(captchaTypeInfo == null){
            logger.logMetadataError(fcParams.cType)
            if (fcParams.errorCb) {
                fcParams.errorCb(CaptchaConstants.errorCodes.failedToLoadProviderScript);
            }
            return elemId;
        }
        try {
            fcParams.fcInstance = new FunCaptcha({
                public_key: captchaTypeInfo.PublicKey,
                target_html: elemId,
                language: getFunCaptchaLanguageCodeFromCurrentLocale(),
                data: {"blob": dxBlob},
                callback: function () {
                    //this is where we do verification with server
                    var userSolveFunCaptchaTime = null;
                    if (userStartedFunCaptchaTime) {
                        userSolveFunCaptchaTime = timestamp() - userStartedFunCaptchaTime;
                        userStartedFunCaptchaTime = null;
                    }

                    var token = getSessionToken();
                    logger.logCaptchaTokenReceivedEventToEventStream(fcParams.cType, token);


                    if (fcParams.returnTokenInSuccessCb === true) {
                        logger.logSuccess(fcParams.cType);
                        logger.logCaptchaEventToEventStream(fcParams.cType, userSolveFunCaptchaTime, true, token);
                        fcParams.successCb(token);
                    } else {
                        verifyWithServer(fcParams, elemId, token, userSolveFunCaptchaTime);
                        fcParams.solvedCb();
                    }
                },
                loaded_callback: function () {
                    fcLoaded(fcParams.cType);
                    fcParams.loadedCb();
                },
                onsuppress: function () {
                    fcSuppressed(fcParams.cType);
                    fcParams.suppressCb();
                },
                onshown: function () {
                    fcShown(fcParams.cType);
                    fcParams.shownCb();
                }
            });
        }
        catch (e) {
            logger.logProviderError(fcParams.cType);
            logger.logCaptchaErrorEventToEventStream(fcParams.cType, getSessionToken(), e);

            if (fcParams.errorCb) {
                fcParams.errorCb(CaptchaConstants.errorCodes.failedToLoadProviderScript, e);
            }
        }
        return elemId;
    }

    function showFunCaptchaInModal () {
        Dialog.open({
            bodyContent: "<div id=\"funcaptcha-modal-body\" class=\"funcaptcha-modal-body\"></div>",
            allowHtmlContentInBody: true,
            showAccept: false,
            showDecline: false,
            xToCancel: true,
            onCloseCallback: dismissFunCaptchaModal
        });
        var captchaElm = $("#game-card-redeem-captcha").removeClass("hidden").detach();
        $("#funcaptcha-modal-body").append(captchaElm);
    }

    function dismissFunCaptchaModal() {
        var captchaElm = $("#game-card-redeem-captcha").addClass("hidden").detach();
        $("#redeem-card-wrapper").append(captchaElm);
        Dialog.close();
    }

    function makeInstance(id, params) {
        var newParams = $.extend({}, defaultParams, params);
        captchaInstances[id] = newParams;
        return newParams;
    }

    // returns the FunCaptcha session token stored in an element
    // that is modified by Arkose Labs' JS API
    function getSessionToken() {
        return $(funCaptchaTokenElem).val();
    }

    function verifyWithServer(fcParams, elemId, token, solveDuration) {
        var data = {
            'fcToken': token
        };
        data = $.extend({}, data, fcParams.extraValidationParams);
        $.ajax({
            method: 'POST',
            data: data,
            url: getCaptcha(fcParams.cType).ApiUrl,
            success: function success() {
                logger.logSuccess(fcParams.cType);
                logger.logCaptchaEventToEventStream(fcParams.cType, solveDuration, true, token);
                fcParams.successCb();
            },
            error: function error() {
                logger.logFail(fcParams.cType);

                if (fcParams.tokenValidationRetries < maxRetriesOnValidationFailure) {
                    if (!retryQueued) {
                        retryQueued = true;
                        setTimeout(function(){ retryFunCaptcha(fcParams, elemId); }, getRetryInterval());
                    }
                }
                else {
                    logger.logMaxFail(fcParams.cType);
                    logger.logCaptchaEventToEventStream(fcParams.cType, solveDuration, false, token);
                    if (fcParams.errorCb) {
                        fcParams.errorCb(CaptchaConstants.errorCodes.failedToVerify, null);
                    }
                }
            }
        });
    }

    function retryFunCaptcha(fcParams, elemId) {
        if (retryQueued) {
            fcParams.tokenValidationRetries += 1;
            logger.logRetried(fcParams.cType);
            renderFunCaptcha(elemId, fcParams);
        }
    }

    function getRetryInterval() {
        return minRetryInterval + Math.floor(Math.random() * (maxRetryInterval - minRetryInterval));
    }

    function timestamp() {
        return new Date().valueOf();
    }

    function fcSuppressed(cType) {
        logger.logSuppressed(cType);
        logger.logCaptchaSuppressedEventToEventStream(cType, getSessionToken());
    }

    function fcShown(cType) {
        logger.logDisplayed(cType);
        logger.logCaptchaShownEventToEventStream(cType, getSessionToken());
    }

    function fcLoaded(cType) {
        // This is a fix specifically for iOS8 devices
        // There are several UI issues with forms and selection in iOS 8 Safari
        // FunCaptcha will not function properly if it is rendered immediately after
        // a text form input
        setTimeout(function () {
            $(funCaptchaIFrameElem).focus();
        }, funCaptchaIFrameFocusTimeout);
    }

    function deCapitalize(val) {
        return val.charAt(0).toLowerCase() + val.slice(1);
    }

    function getFunCaptchaLanguageCodeFromCurrentLocale() {
        if (Intl) {
            var intl = new Intl();
            if (CaptchaConstants.localeToFunCaptchaLanguageCodeMap.hasOwnProperty(intl.locale)) {
                return CaptchaConstants.localeToFunCaptchaLanguageCodeMap[intl.locale];
            }
        }

        return undefined;
    }

    return {
        types: $.extend({}, CaptchaConstants.types),

        setMaxRetriesOnTokenValidationFailure: function (value) {
            maxRetriesOnValidationFailure = value;
        },

        setRetryIntervalRange: function (min, max) {
            minRetryInterval = min;
            maxRetryInterval = max;
        },

        reset: resetFunCaptcha,

        render: renderFunCaptcha,

        addCaptchaTypes: function (types, camelCaseTypes) {
            if (!types) {
                return;
            }

            types.forEach(function (type) {
                var captchaType = {
                    Type: camelCaseTypes ? deCapitalize(type.Type) : type.Type,
                    ApiUrl: type.ApiUrl,
                    PublicKey: type.PublicKey
                };

                var dedupeKey = JSON.stringify(captchaType);
                if (!captchaTypeDedupe.hasOwnProperty(dedupeKey)) {
                    captchaTypeDedupe[dedupeKey] = captchaType;
                    captchaTypes.push(captchaType);
                }
            });
        },

        setPerAppTypeLoggingEnabled: function (enabled) {
            logger.setPerAppTypeLoggingEnabled(enabled);
        },

        showFunCaptchaInModal: showFunCaptchaInModal,

        dismissFunCaptchaModal:dismissFunCaptchaModal,

        //exposed for unit testing purpose.
        captchaInstances: captchaInstances,
        loggerInstance: logger
    }
}());

export default jqFunCaptchaService ;
