"use strict";

import CaptchaConstants from '../../../jquery/captcha/constants/captchaConstants';
import FunCaptcha from '../../../jquery/captcha/services/funCaptchaService';
import captchaV2 from '../captchaV2Module';

function funCaptchaService($q, $log, $window, captchaV2Constants, captchaV2Service) {
    "ngInject";
    var events = {};
    
    var fireEvent = function (funCaptchaType, eventName, data) {
        if (!events.hasOwnProperty(funCaptchaType)) {
            return;
        }
        
        events[funCaptchaType].forEach(function (event) {
            if (event.name === eventName) {
                event.handle(data);
            }
        });
    };

    var addEvent = function (funCaptchaType, eventName, handler) {
        if (handler) {
            events[funCaptchaType] = events[funCaptchaType] || [];
            events[funCaptchaType].push({
                name: eventName,
                handle: handler
            });
        }
    };

    var clearEvents = function (funCaptchaType) {
        events[funCaptchaType] = [];
    };

    var mapFunCaptchaErrorCodeToCaptchaV2ErrorCode = function (funcaptchaErrorCode) {
        switch (funcaptchaErrorCode) {
            case CaptchaConstants.errorCodes.failedToLoadProviderScript:
                return captchaV2Constants.errorCodes.internal.failedToLoadProviderScript;
            case CaptchaConstants.errorCodes.failedToVerify:
                return captchaV2Constants.errorCodes.internal.failedToVerify;
            default:
                return captchaV2Constants.errorCodes.internal.unknown;
        }
    };

    var render = function (elementId, captchaActionType, shownEvent, returnTokenInSuccessCb, inputParams, extraValidationParams) {
        return $q(function (resolve, reject) {
            var funCaptchaType = captchaV2Constants.funCaptchaCaptchaTypes[captchaActionType];
            var captchaInfo = "\n\telementId: " + elementId
                + "\n\tcaptchaActionType: " + captchaActionType
                + "\n\tfunCaptchaType: " + funCaptchaType;

            $log.debug("Render captcha" + captchaInfo);

            if (!funCaptchaType) {
                $log.warn("Missing funCaptchaType for " + captchaActionType);
                reject(captchaV2Constants.errorCodes.internal.missingActionType);
                return;
            }

            addEvent(funCaptchaType, captchaV2Constants.funCaptchaEvents.resolve, resolve);
            addEvent(funCaptchaType, captchaV2Constants.funCaptchaEvents.reject, reject);
            addEvent(funCaptchaType, captchaV2Constants.funCaptchaEvents.shown, shownEvent);

            var successCb;
            if (returnTokenInSuccessCb) {
                successCb = function (fcToken) {
                    captchaInfo += "\ntoken: " + fcToken;
                    $log.debug("Passed captcha" + captchaInfo);
                    fireEvent(funCaptchaType, captchaV2Constants.funCaptchaEvents.resolve, fcToken);
                    clearEvents(funCaptchaType);
                }
            } else {
                successCb = function () {
                    $log.debug("Passed captcha" + captchaInfo);
                    fireEvent(funCaptchaType, captchaV2Constants.funCaptchaEvents.resolve);
                    clearEvents(funCaptchaType);
                }
            }

            FunCaptcha.render(elementId, {
                cType: funCaptchaType,

                inputParams: inputParams,

                returnTokenInSuccessCb: returnTokenInSuccessCb,

                // These methods are thrown away on subsequent calls.
                successCb: successCb,

                shownCb: function () {
                    $log.debug("Captcha shown" + captchaInfo);
                    fireEvent(funCaptchaType, captchaV2Constants.funCaptchaEvents.shown);
                },

                errorCb: function (errorCode, exception) {
                    if (exception) {
                        $log.error("Failed captcha (" + errorCode + ":) " + exception + captchaInfo);
                    } else {
                        $log.debug("Failed captcha (" + errorCode + ")" + captchaInfo);
                    }

                    var externalErrorCode = mapFunCaptchaErrorCodeToCaptchaV2ErrorCode(errorCode);

                    fireEvent(funCaptchaType, captchaV2Constants.funCaptchaEvents.reject, externalErrorCode);
                    clearEvents(funCaptchaType);
                },

                extraValidationParams: extraValidationParams || {}
            });
        });
    };

    captchaV2Service.getMetadata().then(function (metadata) {
        var publicKeys = metadata.funCaptchaPublicKeys;
        var captchaTypes = [];

        for (var funCaptchaType in captchaV2Constants.funCaptchaPublicKeyMap) {
            if (!captchaV2Constants.funCaptchaPublicKeyMap.hasOwnProperty(funCaptchaType)) {
                return;
            }

            var publicKeyType = captchaV2Constants.funCaptchaPublicKeyMap[funCaptchaType];
            if (publicKeyType && publicKeys.hasOwnProperty(publicKeyType)) {
                captchaTypes.push({
                    Type: funCaptchaType,
                    ApiUrl: captchaV2Constants.urls.funCaptchaRedeem[publicKeyType], // this does not need to be defined for BEDEV2
                    PublicKey: publicKeys[publicKeyType]
                });
            } else {
                $log.warn("Missing public key for: " + funCaptchaType + "\n\tpublicKeyType: " + publicKeyType);
            }
        }

        $log.debug("Add captcha types from new webapp:", captchaTypes);

        FunCaptcha.addCaptchaTypes(captchaTypes, false);
    }, function () {
        $log.debug("Failed to load captcha metadata for funCaptchaService. FunCaptcha will not work properly.");
    });

    return {
        render: render
    };
}

captchaV2.factory('funCaptchaService', funCaptchaService);

export default funCaptchaService;
