"use strict";
import $ from 'jquery';

import CaptchaConstants from '../constants/captchaConstants';
import { UrlParser, DeviceMeta, EventStream } from 'Roblox';

/*
 * This should be the logging service used by Captcha related code.
 * Google's recaptcha will be refactored to use this later.
 * Usage: logger = new Roblox.CaptchaLogger("FunCaptcha");
 */
function CaptchaLogger(provider) {
    var constants = CaptchaConstants,
        serviceData = $.extend({}, constants.serviceData),
        logConstants = serviceData.logConstants,
        perAppTypeLoggingEnabled = false,
        appType;

    this.provider = provider;

    this.setPerAppTypeLoggingEnabled = function (enabled) {
        // Only enable logging if we find a valid device type through a URL query
        // parameter or the device type meta tag
        var setAppType = null;
        if (UrlParser) {
            var queryAppType = UrlParser.getParameterValueByName("appType");
            if (constants && constants.appTypes && constants.appTypes.hasOwnProperty(queryAppType)) {
                setAppType = queryAppType;
            }
        }
        if (setAppType == null && DeviceMeta && DeviceMeta().isInApp) {
            setAppType = DeviceMeta().appType;
        }
        if (setAppType !== null) {
            perAppTypeLoggingEnabled = enabled;
            appType = setAppType;
        }
    }
    
    // exposed for unit testing
    this.fireEvent = function (cType, eventName) {
        eventName = capitalize(cType + this.provider + eventName);
        if (window.EventTracker) {
            window.EventTracker.fireEvent(appendAppTypeIfEnabled(eventName));
        }
    }

    this.startStatisticsSequence = function (eventName) {
        if (window.EventTracker) {
            // This will not log to an ephemeral statistics sequence until one of the
            // "end" functions is called
            window.EventTracker.start(appendAppTypeIfEnabled(eventName));
        }
    }

    this.endStatisticsSequenceWithSuccess = function (eventName) {
        if (window.EventTracker) {
            window.EventTracker.endSuccess(appendAppTypeIfEnabled(eventName));
        }
    }

    this.endStatisticsSequenceWithFailure = function (eventName) {
        if (window.EventTracker) {
            window.EventTracker.endFailure(appendAppTypeIfEnabled(eventName));
        }
    }

    this.logSuccess = function (cType) {
        this.fireEvent(cType, logConstants.successSuffix);
        this.endStatisticsSequenceWithSuccess(capitalize(cType + this.provider + logConstants.completedTimeSequenceSuffix));
    }

    this.logFail = function (cType) {
        this.fireEvent(cType, logConstants.failSuffix);
    }

    this.logMaxFail = function(cType) {
        this.fireEvent(cType, logConstants.maxFailSuffix);
        this.endStatisticsSequenceWithFailure(capitalize(cType + this.provider + logConstants.completedTimeSequenceSuffix));
    }

    this.logRetried = function (cType) {
        this.fireEvent(cType, logConstants.retrySuffix);
    }

    this.logTriggered = function (cType) {
        this.fireEvent(cType, logConstants.triggeredSuffix);
        this.startStatisticsSequence(capitalize(cType + this.provider + logConstants.completedTimeSequenceSuffix));
    }

    this.logInitialized = function (cType) {
        this.fireEvent(cType, logConstants.initializedSuffix);
    }

    this.logSuppressed = function (cType) {
        this.fireEvent(cType, logConstants.suppressedSuffix);
    }

    this.logDisplayed = function (cType) {
        this.fireEvent(cType, logConstants.displayedSuffix);
    }

    this.logProviderError = function (cType) {
        this.fireEvent(cType, logConstants.providerErrorSuffix);
    }

    this.logMetadataError = function (cType) {
        this.fireEvent(cType, logConstants.metadataErrorSuffix);
    }

    this.logCaptchaShownEventToEventStream = function (cType, sessionToken) {
        this.logCaptchaInitiatedEventToEventStream(cType, logConstants.captchaInitiatedChallengeTypes.visible, sessionToken);
    }

    this.logCaptchaSuppressedEventToEventStream = function (cType, sessionToken) {
        this.logCaptchaInitiatedEventToEventStream(cType, logConstants.captchaInitiatedChallengeTypes.hidden, sessionToken);
    }

    this.logCaptchaErrorEventToEventStream = function (cType, sessionToken, exception) {
        this.logCaptchaInitiatedEventToEventStream(cType, logConstants.captchaInitiatedChallengeTypes.error, sessionToken, exception.toString());
    }

    this.logCaptchaInitiatedEventToEventStream = function (cType, captchaChallengeType, sessionToken, message) {
        if (EventStream) {
            var context = cType;
            var eventParams = { type: captchaChallengeType, provider: this.provider };

            if (sessionToken !== '' && typeof (sessionToken) !== 'undefined') {
                eventParams.session = sessionToken;
            }

            if (typeof (message) !== 'undefined') {
                eventParams.message = message;
            }

            EventStream.SendEventWithTarget(logConstants.eventStreamCaptchaInitiatedEventName,
                context,
                eventParams,
                EventStream.TargetTypes.WWW);
        }
    }

    this.logCaptchaTokenReceivedEventToEventStream = function (cType, sessionToken) {
        if (EventStream) {
            var context = cType;
            EventStream.SendEventWithTarget(logConstants.eventStreamCaptchaTokenReceivedEventName,
                context,
                { provider: this.provider, session: sessionToken },
                EventStream.TargetTypes.WWW);
        }
    }

    this.logCaptchaEventToEventStream = function(cType, solveDuration, isSuccessful, sessionToken) {
        if (EventStream) {
            solveDuration = solveDuration || 0;
            var success = isSuccessful ? "true" : "false";
            var context = cType;
            EventStream.SendEventWithTarget(logConstants.eventStreamCaptchaEventName,
                context,
                { solveDuration: solveDuration, success: success, provider: this.provider, session: sessionToken || "" },
                EventStream.TargetTypes.WWW);
        }
    }

    function appendAppTypeIfEnabled(eventName) {
        if (perAppTypeLoggingEnabled && typeof (appType) !== "undefined") {
            return eventName + "_" + appType;
        }

        return eventName;
    }

    function capitalize(val) {
        return val.charAt(0).toUpperCase() + val.slice(1);
    }
}


export default CaptchaLogger;
