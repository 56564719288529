"use strict";

import captchaV2 from '../captchaV2Module';

function captchaV2Controller(captchaV2Service, captchaV2Constants, funCaptchaService, $scope) {
    "ngInject";

    var ctrl = this;
    var active = false;
    var lastActivated = false;

    ctrl.id = null;
    ctrl.captchaEnded = function () {
        ctrl.activated = false;
        ctrl.shown = false;
        active = false;
    };

    ctrl.captchaShown = function () {
        ctrl.shown = true;
        $scope.$apply();
    };

    ctrl.hideCaptcha = function () {
        ctrl.activated = false;        
        if (ctrl.captchaDismissed) {
            ctrl.captchaDismissed()();
        }
    };

    ctrl.getCaptchaClasses = function () {
        return {
            "captcha-activated": ctrl.shown && ctrl.activated
        };
    };
    
    var init = function () {
        if (ctrl.id === null) {
            ctrl.id = captchaV2Service.getCaptchaId();
        }
        
        if (!ctrl.activated || active) {
            return;
        }

        active = true;

        var returnTokenInSuccessCb = ctrl.returnTokenInSuccessCb === true;
        var successCb;
        if (returnTokenInSuccessCb) {
            successCb = function (fcToken) {
                if (ctrl.activated) {
                    var captchaData = {
                        captchaToken: fcToken,
                        captchaProvider: captchaV2Constants.captchaProviders.arkoseLabs
                    };
                    ctrl.captchaPassed()(captchaData);
                }

                ctrl.captchaEnded();
            }
        } else {
            successCb = function () {
                if (ctrl.activated) {
                    ctrl.captchaPassed()();
                }

                ctrl.captchaEnded();
            }
        }

        funCaptchaService
            .render(ctrl.id, ctrl.captchaActionType, ctrl.captchaShown, returnTokenInSuccessCb, ctrl.inputParams, ctrl.extraValidationParams)
            .then(successCb,
                function (errorCode) {
                    if (ctrl.activated) {
                        ctrl.captchaFailed()(errorCode);
                    }

                    ctrl.captchaEnded();
                });
    };

    // doCheck required for two way binding change detection: https://stackoverflow.com/a/41978174/1663648
    var doCheck = function () {
        if (lastActivated !== ctrl.activated) {
            lastActivated = ctrl.activated;
            ctrl.$onInit();
        }
    };

    ctrl.$onInit = init;
    ctrl.$doCheck = doCheck.bind(ctrl);
}


captchaV2.controller('captchaV2Controller', captchaV2Controller);

export default captchaV2Controller;
